import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/Layout';
import { Markdown, Container } from '../components/theme';
import Seo from '../components/Seo';

function ImprintPage({
  data: {
    imprint: { nodes },
    // metaData: metaData,
  },
}) {
  const { imprintText } = nodes[0];

  return (
    <Layout lightTheme>
      <Container narrow>
        <Markdown source={imprintText.data.Text} />
      </Container>
    </Layout>
  );
}

export const query = graphql`
  {
    imprint: allStrapiImprint {
      nodes {
        imprintText: Text {
          data {
            Text
          }
        }
      }
    }
    metaData(yamlId: { eq: "imprint" }) {
      description
      sitetitle
    }
  }
`;

export default ImprintPage;

// eslint-disable-next-line react/prop-types
export function Head({ data }) {
  // eslint-disable-next-line react/prop-types
  return <Seo title={data.metaData.sitetitle} description={data.metaData.description} />;
}
